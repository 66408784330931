import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { motion, AnimatePresence } from "framer-motion";

interface AccordionProps {
  title: string;
  content: string;
}

const Accordion: React.FC<AccordionProps> = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="shadow-md p-6 border-[1px] border-solid border-gray/10 rounded-xl">
      <div
        className="flex justify-between items-center text-2xl font-bold cursor-pointer"
        onClick={toggleAccordion}
      >
        <span className="text-xl">{title}</span>
        {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="mt-4 text-lg"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: "auto", opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <p
              className="text-gray text-sm"
              dangerouslySetInnerHTML={{ __html: content }}
            ></p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Accordion;
