import { useNavigate } from "react-router-dom";

export const ButtonExploreArt = ({
  point,
  className,
}: {
  point: string;
  className?: string;
}) => {
  const navigate = useNavigate();
  const handleRedirectAllProducts = () => {
    navigate(point);
  };

  return (
    <button
      className={`bg-primary text-black px-4 py-2 filter hover:brightness-105 ${className}`}
      onClick={handleRedirectAllProducts}
    >
      EXPLORE NOSSA ARTE
    </button>
  );
};
