import { useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import { RiShoppingCart2Line } from "react-icons/ri";
import { useCart } from "@/context/CartContext";
import { FaRegTrashAlt } from "react-icons/fa";
import { formatCurrency } from "@/utils/formatCurrency";
import { truncate } from "@/utils/truncate";

export const ShoppingCart = () => {
  const { items, removeItem, clearCart } = useCart();
  const [isOpen, setIsOpen] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    const calculatePrice = () => {
      const total = items.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
      );
      setTotalPrice(total);
    };
    calculatePrice();
  }, [items]);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleRemoveItems = (
    id: string,
    variant: string,
    price: number,
    quantity: number
  ) => {
    setTotalPrice((prevTotal) => prevTotal - price * quantity);
    removeItem(id, variant);
  };

  const handlePlaceOrder = () => {
    const phoneNumber = process.env.NEXT_PUBLIC_PHONE_NUMBER;
    let messageTemplate = "*Pedido:*\n";

    items.forEach((item) => {
      const itemMessage = `Produto: ${item.name}\nSKU: ${item.sku}\nVariante: ${item.variant}\nQuantidade: ${item.quantity}\nLink: ${item.link}\n\n`;
      messageTemplate += itemMessage;
    });

    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      messageTemplate
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  return (
    <div>
      <button
        onClick={toggleDrawer}
        className="relative flex items-center text-2xl text-marfim"
      >
        <RiShoppingCart2Line className="text-marfim text-2xl" />
        <div className="absolute inline-flex items-center justify-center w-5 h-5 text-[10px] font-bold text-white bg-primary border-2 border-white rounded-full -top-2 -end-2 dark:border-gray-900">
          {items.length}
        </div>
      </button>

      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              className="fixed inset-0 bg-black bg-opacity-50 z-50"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              onClick={toggleDrawer}
            />
            <motion.div
              className="fixed top-0 right-0 w-full sm:w-80 h-full bg-marfim shadow-lg z-50"
              initial={{ x: "100%" }}
              animate={{ x: 0 }}
              exit={{ x: "100%" }}
              transition={{ type: "tween" }}
            >
              <div className="flex flex-col h-full">
                <div className="p-6 overflow-y-auto flex-1 no-scrollbar">
                  <div className="flex justify-between items-center mb-4">
                    <h2 className="text-lg font-semibold">
                      Seu carrinho ({items.length})
                    </h2>
                    <button onClick={toggleDrawer} className="text-xl">
                      <AiOutlineClose />
                    </button>
                  </div>
                  <div className="border-b-[1px] border-solid border-gray/30 mb-4"></div>
                  <button
                    onClick={clearCart}
                    className="text-gray hover:text-red-600 transition duration-200 mb-6"
                  >
                    Limpar Carrinho
                  </button>
                  <ul className="flex flex-col space-y-4">
                    <AnimatePresence>
                      {items.map((item) => (
                        <>
                          <motion.li
                            key={item.id + item.variant}
                            initial={{ opacity: 0, x: 50 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: -50 }}
                            transition={{ duration: 0.3 }}
                            className="flex items-center justify-between"
                          >
                            <img
                              src={item.src}
                              alt={item.name}
                              className="w-20 h-20 object-cover mr-4 border-[1px] border-solid border-gray/30"
                            />
                            <div className="flex-1">
                              <p className="text-sm mb-1">
                                {Boolean(process.env.PRICES) &&
                                  formatCurrency(item.price)}
                              </p>
                              <h3 className="text-sm font-medium">
                                {truncate(item.name, 55)}
                              </h3>
                              <p className="text-xs text-gray">
                                Variante: {item.variant}
                              </p>
                              <p className="text-xs text-gray">
                                Quantidade: {item.quantity}
                              </p>
                            </div>
                            <button
                              onClick={() =>
                                handleRemoveItems(
                                  item.id,
                                  item.variant,
                                  item.price,
                                  item.quantity
                                )
                              }
                              className="text-gray hover:text-red-600"
                            >
                              <FaRegTrashAlt size={20} />
                            </button>
                          </motion.li>
                          <div className="border-b-[1px] border-solid border-gray/30 mb-4"></div>
                        </>
                      ))}
                    </AnimatePresence>
                  </ul>
                </div>
                {items.length > 0 && (
                  <div className="p-4 border-t-[1px] border-solid border-gray/30">
                    <p className="text-lg font-bold mb-2">
                      {Boolean(process.env.PRICES) &&
                        formatCurrency(totalPrice)}
                    </p>
                    <button
                      onClick={handlePlaceOrder}
                      className="w-full bg-primary text-marfim filter hover:brightness-110 transition duration-300 py-2"
                    >
                      FAZER PEDIDO
                    </button>
                  </div>
                )}
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};
