import { ButtonExploreArt } from "@/components/ButtonExploreArts";

const ArtSection = () => {
  return (
    <section className="py-28">
      <div className="flex flex-wrap justify-center -mb-64">
        <div className="max-w-md m-4 relative">
          <img
            src="./assets/art-1.jpg"
            alt="Image 1"
            className="w-full h-full object-cover"
          />
          <div className="absolute bottom-0 left-0 p-6 text-white bg-gradient-to-t from-black/45 via-transparent to-transparent">
            <h2 className="text-2xl font-semibold mb-2 text-marfim">
              A arte do ouro em peças únicas
            </h2>
            <p className="mb-4 text-marfim">
              Descubra a beleza artesanal de peças criadas com o mais puro ouro,
              refletindo tradição e sofisticação.
            </p>
            <ButtonExploreArt point="/products" />
          </div>
        </div>
        <div className="max-w-md m-4 relative">
          <img
            src="./assets/art-2.jpg"
            alt="Image 2"
            className="w-full h-full object-cover"
          />
          <div className="absolute bottom-0 left-0 p-6 text-white bg-gradient-to-t from-black/45 via-transparent to-transparent">
            <h2 className="text-2xl font-semibold mb-2 text-marfim">
              A arte do ouro em peças únicas
            </h2>
            <p className="mb-4 text-marfim">
              Cada peça é uma obra-prima, combinando elegância e exclusividade
              para adornar sua coleção pessoal.
            </p>
            <ButtonExploreArt point="/products" />
          </div>
        </div>
      </div>
      <div className="w-full h-72 bg-primary"></div>
    </section>
  );
};

export default ArtSection;
