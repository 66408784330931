// components/Footer.js
import {
  FaInstagram,
  FaFacebook,
  FaTwitter,
  FaTiktok,
  FaLinkedinIn,
} from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-primary py-6 w-full">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center">
        <div
          className="text-black text-xl uppercase leading-10 tracking-widest mb-6 md:mb-0 mx-4"
          style={{ letterSpacing: "10px !important" }}
        >
          {process.env.TITLE}
        </div>
        <div className="flex flex-wrap items-center justify-start  mx-4">
          {process.env.INSTAGRAM && (
            <a
              href={process.env.INSTAGRAM}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2 px-2 mt-2"
            >
              <div className="p-2 bg-black text-primary rounded">
                <FaInstagram size={20} />
              </div>
              <span className="text-black">Instagram</span>
            </a>
          )}
          {process.env.FACEBOOK && (
            <a
              href={process.env.FACEBOOK}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2 px-2 mt-2"
            >
              <div className="p-2 bg-black text-primary rounded">
                <FaFacebook size={20} />
              </div>
              <span className="text-black">Facebook</span>
            </a>
          )}
          {process.env.TWITTER && (
            <a
              href={process.env.TWITTER}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2 px-2 mt-2"
            >
              <div className="p-2 bg-black text-primary rounded">
                <FaTwitter size={20} />
              </div>
              <span className="text-black">Twitter</span>
            </a>
          )}
          {process.env.LINKEDIN && (
            <a
              href={process.env.LINKEDIN}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2 px-2 mt-2"
            >
              <div className="p-2 bg-black text-primary rounded">
                <FaLinkedinIn size={20} />
              </div>
              <span className="text-black">Linkedin</span>
            </a>
          )}
          {process.env.TIKTOK && (
            <a
              href={process.env.TIKTOK}
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2 px-2 mt-2"
            >
              <div className="p-2 bg-black text-primary rounded">
                <FaTiktok size={20} />
              </div>
              <span className="text-black">Tiktok</span>
            </a>
          )}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
