import { SliderProduct } from "@/components/SliderProducts";
import { useCollections } from "@/context";
import { Product } from "@/DTOs/Product.DTO";
import api from "@/utils/api";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const NewArtsSection = () => {
  const navigate = useNavigate();
  const { collections } = useCollections();
  const [products, setProducts] = useState<Product[]>([]);

  useEffect(() => {
    const fetchCollections = async () => {
      if (collections.length > 0) {
        try {
          // Selecionar uma coleção aleatória
          const randomIndex = Math.floor(Math.random() * collections.length);
          const randomCollection = collections[randomIndex];

          const response = await api.get(
            `/all_products/${randomCollection.id}`
          );
          setProducts(response.data.products);
        } catch (error) {
          console.error("Erro ao buscar produtos:", error);
        }
      }
    };

    fetchCollections();
  }, [collections]); // Dependência correta para aguardar o carregamento das coleções

  const handleClick = (id: string | undefined) => {
    navigate(`/product/${id}`);
  };

  return (
    <section>
      <SliderProduct
        loading={!products.length}
        title="Novidades"
        items={products}
        handleClick={handleClick}
      />
    </section>
  );
};
