import api from "@/utils/api";
import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  FC,
} from "react";

interface ImageCollectionType {
  src: string;
  alt: string;
}

export interface CollectionListing {
  id: number;
  handle: string;
  title: string;
  body_html: string;
  image?: ImageCollectionType;
}

interface CollectionsContextType {
  collections: CollectionListing[];
  loading: boolean;
  error: string | null;
}

const CollectionsContext = createContext<CollectionsContextType | undefined>(
  undefined
);

export const CollectionsProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [collections, setCollections] = useState<CollectionListing[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        const response = await api.get("/custom_collections.json");

        if (response.status !== 200) {
          throw new Error("Network response was not ok");
        }
        const data = response.data;

        setCollections(data.custom_collections);
      } catch (error) {
        setError("Error fetching collections");
      } finally {
        setLoading(false);
      }
    };

    fetchCollections();
  }, []);

  return (
    <CollectionsContext.Provider
      value={{
        collections,
        loading,
        error,
      }}
    >
      {children}
    </CollectionsContext.Provider>
  );
};

export const useCollections = (): CollectionsContextType => {
  const context = useContext(CollectionsContext);
  if (!context) {
    throw new Error("useCollections must be used within a CollectionsProvider");
  }
  return context;
};
