import { useEffect, useState } from "react";

export const Logo = () => {
  const [logo, setLogo] = useState<string>("");

  useEffect(() => {
    const checkLogo = async () => {
      try {
        const checkFileExists = async (url: string) => {
          try {
            const response = await fetch(url);
            if (response.ok) {
              const blob = await response.blob();
              return blob.type.startsWith("image/");
            }
            return false;
          } catch {
            return false;
          }
        };

        const [logoPngExists, logoJpgExists] = await Promise.all([
          checkFileExists("/logo.png"),
          checkFileExists("/logo.jpg"),
        ]);

        if (logoPngExists) {
          setLogo("/logo.png");
        } else if (logoJpgExists) {
          setLogo("/logo.jpg");
        }
      } catch (error) {
        console.error("Error checking for logo:", error);
      }
    };

    checkLogo();
  }, []);

  return (
    <div className="flex items-center">
      {logo && <img src={logo} alt="Logo" className="h-10" />}
    </div>
  );
};
