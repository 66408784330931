import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SkeletonCard } from "@/components/SkeletonCard";
import { useFilterContext } from "@/context/SearchContext";
import { formatCurrency } from "@/utils/formatCurrency";
import { calculateFloat } from "@/utils/calculateFloat";
import { truncate } from "@/utils/truncate";

const AllProducts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    filteredProducts,
    loading,
    error,
    loadMoreProducts,
    hasMoreProducts,
    setSearchTerm,
  } = useFilterContext();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const searchTerm = queryParams.get("search");

    // Se houver um termo de busca na URL, usamos esse termo e evitamos carregar todos os produtos
    if (searchTerm) {
      setSearchTerm(searchTerm); // Atualiza o contexto com o termo de busca
    } else {
      setSearchTerm(''); // Reseta o termo de busca se não houver na URL
    }
  }, [location.search, setSearchTerm]);

  const handleClick = (id: number) => {
    navigate(`/product/${id}`);
  };

  if (error) return <div>Error loading products: {error}</div>;

  return (
    <main className="container min-h-screen mx-auto px-4 pt-28 pb-8">
      <p className="text-xs mb-4 text-gray font-normal leading-normal tracking-wide">
        <a href="/">inicio</a> / <span className="cursor-pointer">produtos</span>
      </p>
      <h1 className="text-3xl sm:text-4xl font-normal leading-10 mb-8">Nossas Artes</h1>
      <div className="mb-4"></div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {loading ? (
          Array.from({ length: 10 }).map((_, index) => <SkeletonCard key={index} />)
        ) : filteredProducts && filteredProducts.length > 0 ? (
          filteredProducts.map((item) =>
            item.images.map((image) => (
              <div
                key={image.id}
                className="hover:scale-105 hover:shadow-lg transition-all duration-150 cursor-pointer mx-auto max-w-64 p-4"
                onClick={() => handleClick(item.id)}
              >
                <div className="border-[1px] border-solid border-gray/20">
                  <img
                    src={image.src}
                    alt={image.alt || "Product Image"}
                    width={image.width}
                    height={image.height}
                    className="w-full h-48 object-cover"
                  />
                </div>
                <p className="text-sm text-gray mt-2">{item.product_type}</p>
                {Boolean(process.env.PRICES) && item.variants.length > 0 && (
                  <p className="text-lg mt-2 font-medium">
                    {formatCurrency(
                      calculateFloat(parseFloat(item.variants[0].price))
                    )}
                  </p>
                )}
                <h2 className="mt-2 text-lg font-medium">
                  {truncate(item.title, 75)}
                </h2>
              </div>
            ))
          )
        ) : (
          <h2 className="text-lg">Ops... Produtos não encontrados!</h2>
        )}
      </div>
      {hasMoreProducts && !loading && (
        <div className="flex justify-center">
          <button
            className="flex justify-center items-center bg-primary text-white md:text-sm text-base py-3 px-4 mt-12 filter hover:brightness-110 transition duration-300"
            onClick={loadMoreProducts}
          >
            Carregar mais
          </button>
        </div>
      )}
    </main>
  );
};

export default AllProducts;
