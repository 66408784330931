import axios from "axios";

// const api = axios.create({
//   baseURL: "/api/admin/api/2023-07",
//   headers: {
//     "Content-Type": "application/json",
//     "Accept": "application/json",
//   },
// });

const api = axios.create({
  baseURL: process.env.API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});
export default api;
