import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet,
} from "react-router-dom";

import Home from "./Pages/Home";
import AllProducts from "./Pages/AllProducts";
import ProductByCollection from "@/Pages/ProductsByCollection";
import ProductPage from "./Pages/Product";
import NotFoundPage from "./Pages/404";
import { Header } from "./components/Header";
import Footer from "./components/Footer";

const Layout: React.FC = () => (
  <div className="min-h-screen flex flex-col justify-between">
    <Header />
    <Outlet />
    <Footer />
  </div>
);

const RouterApp: React.FC = () => {
  useEffect(() => {
    document.title = process.env.TITLE || "jewelry";
  }, []);

  return (
    <Router>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<AllProducts />} />
          <Route
            path="/collection/:product/:id"
            element={<ProductByCollection />}
          />
          <Route path="/product/:id" element={<ProductPage />} />
          <Route path="/*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default RouterApp;
